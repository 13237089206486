import { useFormik } from "formik";
import { IAO } from "infrastructure/interfaces";
import React, { SetStateAction, useEffect, useState } from "react";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getFieldPermissionsUpdateAdvanceReportApplications,
  updateAdvanceReportApllicationCashAmounts,
} from "services/ApiService";
import { updateDetailedAO } from "store/report/actions";
import { getAdvanceReportApplication } from "store/selectors";
import { Button } from "ui/Button";
import { Modal } from "ui/Modal";
import AOCashAmountInput from "./AOCashAmountInput";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  id: number;
};

type Error = {
  Messages: {
    Ru: string;
    En: string;
  }[];
}

// "Итого получено" модальное окно
const AOCashAmountModal: FC<Props> = ({ open, setOpen, id }) => {
  const advanceReportApplication: IAO = useSelector(
    getAdvanceReportApplication
  );

  const { t, i18n } = useTranslation();
  const language = i18n.language.charAt(0).toUpperCase() + i18n.language.slice(1);
  const dispatch = useDispatch();

  const [editPermissions, setEditPermissions] = useState<string[]>([]);
  const [errors, setErrors] = useState<Error>();

  const getEditPermissions = async () => {
    const result = await getFieldPermissionsUpdateAdvanceReportApplications(id);
    if (result.headers.success) {
      setEditPermissions(result.data);
    }
  };

  const initialValues = {
    advanceCashAmountRemainder:
      advanceReportApplication.advanceCashAmountRemainder,
    advanceCashAmountOverspend:
      advanceReportApplication.advanceCashAmountOverspend,
    advanceCashAmountReceived:
      advanceReportApplication.advanceCashAmountReceived,
  };

  const permissions = [
    "AdvanceCashAmountReceived",
    "AdvanceCashAmountOverspend",
    "AdvanceCashAmountRemainder",
  ];

  const closeModal = () => setOpen(false);

  const onSubmit = async (
    values: typeof initialValues,
  ) => {
    const response = await updateAdvanceReportApllicationCashAmounts(
      id,
      values
    );
    if (response.headers.success) {
      dispatch(updateDetailedAO(id));
      setOpen(false);
    } else {
      setErrors(response.data);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  const sumbitForm = () => formik.submitForm();

  useEffect(() => {
    getEditPermissions();
  }, [id]);

  useEffect(() => {
    open &&
      dispatch(updateDetailedAO(id)) &&
      formik.resetForm({
        values: initialValues,
      });
  }, [open]);

  const Footer = () => {
    if (editPermissions.some((el) => permissions.includes(el))) {
      return (
        <div className="w-100 d-flex justify-content-end pb-2 px-4">
          <Button
            big
            onClick={closeModal}
            buttonText={t("modals.ad_act.cancel")}
            buttonColor="default"
          />
          <Button
            big
            onClick={sumbitForm}
            type="submit"
            buttonText={t("assistance_list.modal.save")}
            buttonColor="green"
          />
        </div>
      );
    } else {
      return (
        <div className="w-100 d-flex justify-content-end pb-2 px-4">
          <Button
            big
            onClick={closeModal}
            buttonText={t("modals.info.close")}
            buttonColor="default"
          />
        </div>
      );
    }
  };

  return (
    <Modal
      isOpen={open}
      title={t("request_detail.request_cost.advanceCashAmountTotalReceived")}
    >
      <div>
        <hr />
        <form
          onSubmit={formik.handleSubmit}
          className="py-2"
          id="cash-amount-form"
        >
          <div className="box-modal-form-block d-flex flex-column px-4">
            <div className="d-flex align-items-center flex-column flex-md-row">
              <AOCashAmountInput
                className={"mb-3 col-md-6 col-12"}
                formik={formik}
                field={"advanceCashAmountRemainder"}
                editPermissions={editPermissions}
              />
              <AOCashAmountInput
                className={"mb-3 col-md-6 col-12"}
                formik={formik}
                field={"advanceCashAmountOverspend"}
                editPermissions={editPermissions}
              />
            </div>
            <div className="d-flex">
              <AOCashAmountInput
                className={"col-md-6 col-12"}
                formik={formik}
                field={"advanceCashAmountReceived"}
                editPermissions={editPermissions}
              />
            </div>
            {errors && errors.Messages && (
              <div className="mt-2 pl-3 text-danger">
                {String(errors.Messages[0][language as "En" | "Ru"])}
              </div>
            )}
          </div>
        </form>
        <hr />
        <Footer />
      </div>
    </Modal>
  );
};

export default AOCashAmountModal;
